import React from 'react';
import { Col, Row } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ImageHero from '../components/image-components/hero/hero';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <Row
      style={{
        fontFamily: 'Montserrat',
        padding: '3rem',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
      justify="space-around"
      align="middle"
    >
      <Col sm={24} md={8} style={{ fontSize: '6rem' }}>
        404
      </Col>
      <Col sm={24} md={16} style={{ fontSize: '2rem' }}>
        Sorry, we couldn't find this page.
      </Col>
    </Row>
    <div style={{ padding: '3rem' }}>
      <ImageHero />
    </div>
  </Layout>
);

export default NotFoundPage;
